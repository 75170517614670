import './App.css';
import Radio from "./Radio.js";

function App() {
    return (
        <div className="App">
            <Radio />
        </div>
    );
}

export default App;
