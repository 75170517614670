import React, { useEffect, useState } from "react";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";

const Radio = () => {
    const [currentTrack, setCurrentTrack] = useState("Loading...");
    const [streamInfo, setStreamInfo] = useState({ name: "Alex_Radio", listeners: 0 });
    const streamUrl = "https://radapi.ru:8443/source";
    const statusUrl = "https://radapi.ru:8443/status-json.xsl";

    // Получение информации о потоке
    const fetchStreamInfo = async () => {
        try {
            const response = await fetch(statusUrl);
            const data = await response.json();
            const source = data.icestats.source || {};
            setCurrentTrack(source.title || "Unknown Track");
            setStreamInfo({
                name: source.server_name || "Alex_Radio",
                listeners: source.listeners || 0,
            });
        } catch (error) {
            console.error("Error fetching stream info:", error);
            setCurrentTrack("Error fetching track info");
            setStreamInfo({ name: "Alex_Radio", listeners: 0 });
        }
    };

    useEffect(() => {
        fetchStreamInfo();
        const interval = setInterval(fetchStreamInfo, 10000); // Обновление каждые 10 секунд
        return () => clearInterval(interval);
    }, []);

    return (
        <div className="radio">
            <div className="headerInfoMini"></div>
            <div className="radio-header">
                <div className="station-info">
                    <h2 className="station-name">{streamInfo.name}</h2>
                    <p className="current-track">{currentTrack}</p>
                    <p className="listeners">Слушатели: {streamInfo.listeners}</p>
                </div>
            </div>
            <AudioPlayer
                className="player"
                src={streamUrl}
                showJumpControls={false}
                layout="horizontal"
                customProgressBarSection={[]}
                customControlsSection={["MAIN_CONTROLS", "VOLUME_CONTROLS"]}
                autoPlayAfterSrcChange={false}
            />
        </div>
    );
};

export default Radio;